import React from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const   SearchBar = ({
  handleShow,
  handleSearch,
  handleSearchBusinessType,
  handleSearchBusinessServices,
  searchInput,
}) => {
  const location = useLocation();
  return (
    <div className="plan-table-heading">
      <h3>
        {location && location.pathname == "/users"
          ? "User List"
          : location.pathname == "/businessServices"
          ? "Business Services"
          : "Business Type"}
      </h3>
      <div className="search-bar">
        <input
          name="search"
          type="text"
          className="form-control"
          placeholder={
            location && location.pathname == "/users"
              ? "Search User"
              : location.pathname == "/businessServices"
              ? "Search Service"
              : "Search Type"
          }
          onChange={
            location.pathname == "/users"
              ? handleSearch
              : location.pathname == "/businessType"
              ? handleSearchBusinessType
              : handleSearchBusinessServices
          }
          value={searchInput ? searchInput : ""}
        />
        <i className="fa fa-search" aria-hidden="true"></i>
      </div>
      <Button onClick={handleShow}>Add</Button>
    </div>
  );
};

export default SearchBar;
