import React from "react";
import Layout from "../../layout/layout";
import NotificationLayout from "../../notification/notificationLayout";
const Notifications = ({notificationCount,setNotificationCount}) => {
  return (
    <>
       <Layout notificationCount={notificationCount}
    setNotificationCount={setNotificationCount}>
      <NotificationLayout />
      </Layout>
    </>
  );
};

export default Notifications;
