import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Switch from "react-switch";

const UserTable = ({
  businessTypeData,
  handleEdit,
  serial_num,
  totalCount,
  pageLimit,
  handleCountSelection,
  handleChangeStatus,
  isDeletedstatus,
  activePage,
  countValue,
  setisdeletedStatus,
  changeCount,
}) => {

  let arr = [];
  if (totalCount >= countValue) {
    let count = Math.ceil(totalCount / 5);
    count = count * pageLimit;
    let newCount = count / 2;
    arr.push(newCount);
    arr.push(count);
  } else {
    let newGeneratedValue1 = 5;
    arr.push(newGeneratedValue1);
  }
  const businessTypeDataFunction = () => {
    const businessTypeAllData =
      businessTypeData &&
      businessTypeData.length > 0 &&
      businessTypeData.map((businessType, index) => {
        return (
          <tr key={index}>
            <td>{serial_num + index + 1}</td>
            <td>{businessType.businessType}</td>
            <td>
              <Switch
                onChange={() => handleChangeStatus(businessType)}
                checked={!businessType.isDeleted}
                width={60}
                onColor="#212529"
              />
            </td>
            <td>
              <i
                className="fa fa-pencil"
                aria-hidden="true"
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleEdit(businessType)}
              ></i>
            </td>
          </tr>
        );
      });
    return businessTypeAllData;
  };
  
  
  return (
    <>
      <Table striped bordered hover responsive>
        <thead  style={{position:"sticky", top:"-1px",zIndex:"999"}}>
          <tr>
            <th style={{position:"sticky"}}>Sr No.</th>
            <th style={{position:"sticky"}}>Name</th>
            <th style={{position:"sticky"}}>Enabled</th>
            <th  style={{position:"sticky"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {businessTypeDataFunction()}
        </tbody>
      </Table>
      {activePage == 1 && (
        <>
          <span>Show</span>
          <select
            name="count"
            value={countValue}
            onChange={(e) => changeCount(e)}
            style={{ width: "100px" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <span>entries</span>
        </>
      )}
    </>
  );
};

export default UserTable;
