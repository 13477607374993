import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL

export const registerUser = (user) => {
  return axios.post(`${apiUrl}/users/`, { ...user });
};

export const loginUser = async (user) => {
  return await axios.post(`${apiUrl}/admin/loginAdmin`, { ...user });
};

export const ForgotAPI = async (user) => {
  return await axios.post(`${apiUrl}/admin/forgot`, { ...user });
};

export const ResetAPI = async (data) => {
  return await axios.post(`${apiUrl}/admin/resetPassword`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const changePassword = async (obj) => {
  try {
    let token = localStorage.getItem("admin_token");
    const response = await axios.post(
      `${apiUrl}/admin/changePassword`,
      obj,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (err) {
    return err;
  }
};
