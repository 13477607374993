import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import ImportTable from "../importTable/ImportTable";
import ImportPopup from "../importpopup/ImportPopup";
const Importfile = ({notificationCount,setNotificationCount}) => {
  const [userId, setUserId] = useState();
  const [errorData, setErrorData] = useState([]);
  const [name, setName] = useState("");
  const handleErrorData = (data) => {
    setErrorData(data.error);
  };

  const handleState = (id) => {
    setUserId(id);
  };
  return (
    <Layout notificationCount={notificationCount}
    setNotificationCount={setNotificationCount}>
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <ImportPopup
              handleState={handleState}
              handleErrorData={handleErrorData}
            />
            <div className="plan-table">
              <ImportTable userId={userId} errorData={errorData} name={name} />
            </div>
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default Importfile;
