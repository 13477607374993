import React, { useState, useEffect } from "react";
import {  Form, Button, Col, Row, Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

const UserModal = ({
  handleClose,
  show,
  edit,
  user,
  changeHandler,
  submitHandler,
  err,
  handleUpdate,
  bussType,
  deleted,
  handleRemove,
  residence,
  items,
  setItems,
}) => {
  const [newItem, setNewItem] = useState([]);

  const {
    email,
    businessName,
    firstName,
    phone,
    mobile,
    state,
    // businessType,
    reffered,
  } = user;

  const referred = ["Google", "Facebook", "Referral"];

  const handleRemoveBusinessType = (selectedList) => {
    setItems(selectedList);
  };

  const handleSelect = (selectedList) => {
    setItems(selectedList);
  };

  const getEditedBussType = () => {
    let arr = [];
    items.map((item) => {
      return arr.push({ type: item.businessType, id: item._id });
    });
    setNewItem(arr);
  };

  useEffect(() => {
    getEditedBussType();
  }, [items]);

  return (
    <Modal
      show={show}
      className="user-modal"
      onHide={handleClose}
    >
      <div className="login1">
        <Modal.Header>
          <Modal.Title>
            {edit ? "Update User" : deleted ? "Delete User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleted ? (
            <div className="popup-form-box">
              {" "}
              <h2 style={{ color: "red" }}>Warning !!</h2>
              <strong>Do you want to Delete this User ? </strong>
            </div>
          ) : (
            <Form>
              <Row>
                <Col xs={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="businessName"
                      placeholder="Business Name"
                      value={businessName}
                      onChange={changeHandler}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {err && err.businessName}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your Name"
                      name="firstName"
                      value={firstName}
                      onChange={changeHandler}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {err && err.firstName}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      disabled={edit && true}
                      placeholder="Email"
                      value={email}
                      onChange={changeHandler}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {err && err.email}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Business Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Business Phone"
                      value={phone}
                      onChange={changeHandler}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {err && err.phone}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobile"
                      placeholder="Mobile Number"
                      value={mobile}
                      onChange={changeHandler}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {err && err.mobile}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Residence</Form.Label>
                    <Form.Select name="state" onChange={changeHandler}>
                      {edit ? (
                        <option key={state}>{state}</option>
                      ) : (
                        <option value="">Select Residence</option>
                      )}
                      {edit
                        ? residence
                            .filter((res) => res.name !== state)
                            .map((res) => (
                              <option key={res.name} value={res.name}>
                                {res.name}
                              </option>
                            ))
                        : residence.map((res) => (
                            <option key={res.name} value={res.name}>
                              {res.name}
                            </option>
                          ))}
                    </Form.Select>
                    <span className="error" style={{ color: "red" }}>
                      {err && err.state}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Business Type</Form.Label>
                    {edit ? (
                      <Multiselect
                        selectedValues={newItem}
                        displayValue="type"
                        className="input-control multiselect"
                        name="items"
                        disable
                      />
                    ) : (
                      <Multiselect
                        className="input-control multiselect"
                        name="items"
                        options={bussType}
                        selectedValues={items}
                        onSelect={handleSelect}
                        onRemove={handleRemoveBusinessType}
                        displayValue="type"
                      />
                    )}
                    <span className="error" style={{ color: "red" }}>
                      {err && err.items}
                    </span>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      How did you hear about Bisi Boulevard?
                    </Form.Label>
                    <Form.Select name="reffered" onChange={changeHandler}>
                      {edit ? (
                        <option value={reffered} key={reffered}>
                          {reffered}
                        </option>
                      ) : (
                        <option value="">Select Reference</option>
                      )}
                      {edit
                        ? referred
                            .filter((refer) => refer !== reffered)
                            .map((refer) => (
                              <option value={refer} key={refer}>
                                {refer}
                              </option>
                            ))
                        : referred.map((refer) => (
                            <option value={refer} key={refer}>
                              {refer}
                            </option>
                          ))}
                    </Form.Select>
                    <span className="error" style={{ color: "red" }}>
                      {err && err.reffered}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {deleted ? (
            <>
              <Button variant="info" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleRemove}>
                Delete
              </Button>
            </>
          ) : (
            <>
              <Button variant="info" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={edit ? handleUpdate : submitHandler}
              >
                {edit ? "Update" : "Add"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UserModal;
