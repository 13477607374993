import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const BusinessTypeModal = ({
  handleClose,
  show,
  businessData,
  handleChange,
  handleSubmit,
  edit,
  handleUpdate,
  err,
  deleted,
  add,
  handleRemove,
}) => {
  const { businessType } = businessData;
  const location = useLocation();

  return (
    <Modal show={show} className="user-modal" onHide={handleClose}>
      <div className="login1">
        <Modal.Header>
          <Modal.Title>
            {location.pathname == "/users"
              ? "User Details"
              : location.pathname == "/businessType" && edit
              ? "Edit Business-Type"
              : location.pathname == "/businessType" && deleted
              ? "Confirm to Delete"
              : location.pathname == "/businessType" && add
              ? "Add Business-Type"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {location.pathname == "/users" ? (
            <div className="popup-form-box">
              {" "}
              <label>First Name</label>
              <input
                className="input1"
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
              <br />
              <label>Mobile</label>
              <input
                className="input1"
                type="number"
                name="mobile"
                value={mobile}
                onChange={handleChange}
              />
              <br />
              <label>Phone</label>
              <input
                className="input1"
                type="number"
                name="phone"
                value={phone}
                onChange={handleChange}
              />
              <br />
              <label>Email</label>
              <input
                className="input1"
                type="email"
                disabled
                name="email"
                value={email}
                onChange={handleChange}
              />
              <br />
              <label>Referred</label>
              <input
                className="input1"
                type="text"
                name="reffered"
                value={reffered}
                onChange={handleChange}
              />
              <br />
              <label>State</label>
              <input
                className="input1"
                type="text"
                name="state"
                value={state}
                onChange={handleChange}
              />
              <br />
              <label>Bussiness Name</label>
              <input
                className="input1"
                type="text"
                name="bussName"
                value={bussName}
                onChange={handleChange}
              />
              <br />
              <label>Bussiness Type</label>
              <input
                className="input1"
                type="text"
                name="bussType"
                value={bussType}
                onChange={handleChange}
              />
            </div>
          ) : deleted ? (
            <div className="popup-form-box">
              {" "}
              <h2 style={{ color: "red" }}>Warning !!</h2>
              <strong>Do you want to Delete this Business Type ? </strong>
            </div>
          ) : (
            <div className="popup-form-box">
              {" "}
              <label>Business Type</label>
              <input
                className="input1"
                type="text"
                pattern="^[a-zA-Z_ ]*$"
                name="businessType"
                value={businessType}
                onChange={handleChange}
                placeholder="Business Type"
              />
              <span className="error" style={{color:"red"}}>{err && err.businessType} </span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {deleted ? (
            <>
              <Button variant="info" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleRemove}>
                Delete
              </Button>
            </>
          ) : (
            <>
              <Button variant="info" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                type="submit"
                onClick={edit && edit ? handleUpdate : handleSubmit}
              >
                {edit && edit ? "Save" : "Add"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default BusinessTypeModal;
