import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as authService from "../../services/authServices";
import { Link, useParams } from "react-router-dom";
import { createNotification } from "../../helper/notification";
import { ToastContainer } from "react-toastify";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = useState({});
  const [data, setData] = useState({
    password: "",
    resetLink: token,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const isFormValid = () => {
    let pwd = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
    );
    if (data.password == "") {
      setError({ password: "New password is required!" });
      return false;
    } else if (!pwd.test(data.password)) {
      setError({
        password:
          "Your password should have at least one special charachter, digits, uppercase and lowercase charachter. Length: 8+ ch-ers.",
      });
      return false;
    }

    if (confirmPassword == "") {
      setError({ confirmPassword: "confirm password is required!" });
      return false;
    } else if (!pwd.test(confirmPassword)) {
      setError({
        confirmPassword:
          "Your password should have at least one special charachter, digits, uppercase and lowercase charachter. Length: 8+ ch-ers.",
      });
      return false;
    } else {
      setError({});
      return true;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = isFormValid();
    if (isValid) {
      if (data.password != confirmPassword) {
        setError({
          ...error,
          confirmPassword: "passwords does not match",
        });
      } else {
        try {
          const loginResponse = await authService.ResetAPI(data);
          if (loginResponse.status == 201) {
            createNotification("success", loginResponse.data.message);
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            createNotification("error", loginResponse.data.message);
          }
        } catch (error) {
          console.log(Error);
        }
      }
    }
  };
  const onchange = (event) => {
    setError({});
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo"></div>
                <h4 className="centre form-group">Reset Password</h4>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    className="form-control form-control-lg"
                    type="password"
                    id="password"
                    placeholder="New Password"
                    name="password"
                    onChange={(e) => {
                      onchange(e);
                    }}
                  />
                  <span className="form-error">{error.password}</span>
                  <label>Confirm Password</label>
                  <input
                    className="form-control form-control-lg"
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span className="form-error">{error.confirmPassword}</span>
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check"></div>
                  <Link to="/" className="auth-link text-black">
                    Sign in to continue
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
