import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

const BusinessServiceModal = ({
  handleClose,
  show,
  businessData,
  setBusinessData,
  handleChange,
  handleSubmit,
  edit,
  handleUpdate,
  err,
  dropDownData,
  deleted,
  handleRemove,
  setNewItem,
  newItem,
}) => {
  const { businessTypeId, service } = businessData;
  const handleChangeBussType = (selectedList) => {
    setBusinessData({ businessTypeId: selectedList });
  };

  const handleRemoveBussType = (selectedList) => {
    setBusinessData({ businessTypeId: selectedList });
  };
  const getEditedBussType = () => {
    let arr = [];
    businessData?.businessTypeId &&
      businessData.businessTypeId.length > 0 &&
      businessData.businessTypeId.map((item) => {
        return arr.push({ type: item.businessType, id: item._id });
      });
    setNewItem(arr);
  };

  useEffect(() => {
    getEditedBussType();
  }, [businessTypeId]);

  return (
    <Modal show={show} className="user-modal" onHide={handleClose}>
      <div className="login1">
        <Modal.Header>
          <Modal.Title>
            {deleted
              ? "Confirm to Delete"
              : edit
              ? "Edit Business-Service"
              : "Add Business-Service"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleted ? (
            <div className="popup-form-box">
              {" "}
              <h2 style={{ color: "red" }}>Warning !!</h2>
              <strong>Do you want to Delete this Business Service ? </strong>
            </div>
          ) : (
            <>
              <div className="popup-form-box">
                {" "}
                <label>Business Type</label>
                {edit ? (
                  <Multiselect
                    className="input-control multiselect"
                    name="businessTypeId"
                    options={dropDownData}
                    selectedValues={newItem}
                    displayValue="type"
                    disable
                  />
                ) : (
                  <Multiselect
                    className="input-control multiselect"
                    name="businessTypeId"
                    options={dropDownData}
                    selectedValues={businessData?.businessTypeId}
                    onSelect={handleChangeBussType}
                    onRemove={handleRemoveBussType}
                    displayValue="type"
                  />
                )}
                <span className="error" style={{ color: "red" }}>
                  {err && err.businessTypeId}{" "}
                </span>
              </div>

              <div className="popup-form-box">
                {" "}
                <label>Business Service</label>
                <input
                  className="input1"
                  type="text"
                  name="service"
                  value={service}
                  onChange={handleChange}
                  placeholder="Business Service"
                />
                <span className="error" style={{ color: "red" }}>
                  {err && err.service}{" "}
                </span>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {deleted ? (
            <>
              <Button variant="info" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleRemove}>
                Delete
              </Button>
            </>
          ) : (
            <>
              <Button variant="info" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                type="submit"
                onClick={edit && edit ? handleUpdate : handleSubmit}
              >
                {edit && edit ? "Save" : "Add"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default BusinessServiceModal;
