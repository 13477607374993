import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL

export const getData = async (pageNo, limit) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${apiUrl}/admin/users/get/${pageNo}/${limit}`,
      { headers: headerData }
    );
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const postUsersData = async (data, tokenResponse) => {
  const headerData = { Authorization: `Bearer ${tokenResponse}` };
  try {
    const response = await axios.post(
      `${apiUrl}/admin/users/create`,
      data,
      { headers: headerData }
    );
    return response;
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const editUserData = async (id, data) => {
  try {
    const token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.put(
      `${apiUrl}/admin/users/edit/${id}`,
      data,
      { headers: headerData }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getsingleUser = async (id) => {
  try {
    const token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.get(
      `${apiUrl}/admin/users/getbyid/${id}`,
      { headers: headerData }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const removeUser = async (id) => {
  try {
    const token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.delete(
      `${apiUrl}/admin/users/removeById/${id}`,
      { headers: headerData }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getSearchedUsers = async (pageNo, limit, text) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${apiUrl}/admin/users/search?page=${pageNo}&limit=${limit}&text=${text}`,
      {
        headers: headerData,
      }
    );
    if (response.status == 200) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const updateStatus = async (id, status) => {
  try {
    const token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.put(
      `${apiUrl}/admin/users/statusChange/${id}/${status}`,
      {},
      {
        headers: headerData,
      }
    );
    if (response.status == 200) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};
