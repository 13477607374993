import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import ReactPagination from "../Pagination/reactPagination";
import * as businessServices from "../../services/businessServices";
import BusinessTypeModal from "../../modals/businessTypeModal";
import SearchBar from "../search/searchBar";
import BusinessTypeTable from "../../table/businessTypeTable";
import { ToastContainer } from "react-toastify";
import { createNotification } from "../../helper/notification";
import NoDataFound from "../dataFound/noDataFound";
import Loader from "../../loader/loader";
import { useSelector } from "react-redux";
import axios from "axios";

const BusinessType = ({ notificationCount, setNotificationCount }) => {
  const [businessTypeData, setBusinessTypeData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState();
  const [edit, setEdit] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [add, setAdd] = useState(false);
  const [pagination, setPagination] = useState(true);
  const [err, setErr] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [isDeletedstatus, setisdeletedStatus] = useState(false);

  const [businessData, setBusinessData] = useState({
    businessType: "",
  });
  const [searchInput, setSearchInput] = useState("");
  const [countValue, setCountValue] = useState(5);
  const [serialNumberVal, setSerialNumberVal] = useState();
  const changeCount = (e) => {
    let value = e.target.value;
    if (searchInput === "") {
      setCountValue(value);
      handleCountSelection(value);
    } else {
      setCountValue(value);
      handleCountSelection(value);
    }
  };
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const tokenResponse = useSelector((state) => state.auth.token);
  const serialNumber = (activePage) => {
    let current_pagenum = activePage;
    let total_records_per_page = countValue ? countValue : pageLimit;
    let serial_num = total_records_per_page * (current_pagenum - 1);
    setSerialNumberVal(serial_num);
  };

  const getAdmin = async () => {
    try {
      let token = localStorage.getItem("admin_token");
      let adminId = localStorage.getItem("userid_");
  
      const data = await axios.get(
        `${apiUrl}/admin/getUser/${adminId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUser(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => getAdmin(), []);
  console.log(user,"useruserusjewojewoljweruser")

  useEffect(() => {
    serialNumber();
  }, [activePage]);

  const handleCountSelection = async (data) => {
    if (searchInput) {
      const textTrim = searchInput.trim();
      const response = await businessServices.searchBusinessTypes(
        1,
        data,
        textTrim
      );
      setBusinessTypeData(response.data);
      setTotalCount(response.count);
    } else {
      getData(activePage, data, tokenResponse);
      setPageLimit(data);
    }
  };

  const handleClose = () => {
    setErr([]);
    setShow(false);
    setAdd(false);
    setTimeout(() => {
      setDeleted(false);
      setEdit(false);
    }, 1000);
    setBusinessData({
      businessType: "",
    });
  };
  const handleShow = () => {
    setAdd(true);
    setShow(true);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const getData = async (activePage, pageLimit, tokenResponse) => {
    setLoader(true);
    if (tokenResponse) {
      const response = await businessServices.getAllBusinessTypes(
        activePage,
        pageLimit,
        tokenResponse
      );
      if (response.totalCount > 0) {
        setLoader(false);
        setPagination(true);
        setBusinessTypeData(response.data);
        serialNumber(activePage);
        setTotalCount(response.totalCount);
      }
      else if (response.totalCount == 0) {
        setLoader(false);
      }
    }
  };

  const handleSearchBusinessType = async (e) => {
    const text = e.target.value;
    setSearchInput(text);
    if (text !== " ") {
      if (text.length > 0) {
        let activePage = 1;
        setActivePage(activePage);
        const response = await businessServices.searchBusinessTypes(
          activePage,
          pageLimit,
          text
        );
        if (response.count <= 5) {
          setPagination(false);
        }
        if (response.data.length > 0) {
          setBusinessTypeData(response.data);
          setTotalCount(response.count);
          setPagination(true);
          serialNumber(activePage);
          setDataFound(false);
        } else {
          setDataFound(true);
          setBusinessTypeData([]);
          setTotalCount(0);
          setPagination(false);
        }
      }

      if (text == "") {
        setDataFound(false);
        if (countValue) {
          let pageActive = 1;
          setActivePage(pageActive);
          getData(activePage, countValue, tokenResponse);
          setPagination(true);
        } else {
          if ((activePage, pageLimit, tokenResponse)) {
            getData(activePage, pageLimit, tokenResponse);
          }
        }
        setPagination(true);
      }

      if (businessTypeData == []) {
        setBusinessTypeData([]);
        setPagination(false);
      }
    }
  };

  const handleDelete = async (id) => {
    setDeleted(true);
    setAdd(false);
    setEdit(false);
    setDeleteId(id);
    handleShow();
  };

  const handleRemove = async () => {
    let id = deleteId;
    const response = await businessServices.removeBusinessType(id);

    if (response.status == 200) {
      createNotification("success", response.data.message);
      if ((activePage, pageLimit, tokenResponse)) {
        getData(activePage, pageLimit, tokenResponse);
      }
      handleClose();
    }
  };

  const handleEdit = (business) => {
    setEdit(true);
    setDeleted(false);
    handleShow();
    const { _id, businessType } = business;
    setEditId(_id);
    setBusinessData({
      businessType,
    });
  };

  const handleUpdate = async () => {
    setDeleted(false);
    if (validation()) {
      const dataObj = {
        businessType: businessData.businessType.trim(),
      };
      if (!dataObj?.businessType) {
        createNotification("error", "Please enter business type");
      } else {
        const response = await businessServices.editBusinessType(
          editId,
          dataObj
        );
        if (response.data.status == 200) {
          setSearchInput("");
          createNotification("success", response.data.message);
          handleClose();
          getData(activePage, pageLimit, tokenResponse);
          document.getElementById("search").value = "";
        } else {
          createNotification("error", response.data.message);
        }
        getData(activePage, pageLimit, tokenResponse);
        handleClose();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = e.target.validity.valid ? value : undefined;
    if (data !== undefined) {
      setBusinessData({ ...businessData, [name]: value });
    }
  };

  const validation = () => {
    const { businessType } = businessData;
    const Error = {};
    let isValid = true;
    if (!businessType) {
      isValid = false;
      Error["businessType"] = "Business type is required";
    } else if (businessType.trim() === "") {
      isValid = false;
      Error["businessType"] = "Please enter business type";
    }
    setErr(Error);
    return isValid;
  };

  const handleSubmit = async () => {
    setDeleted(false);
    if (validation()) {
      const dataObj = {
        businessType: businessData.businessType.trim(),
      };
      if (!dataObj?.businessType) {
        createNotification("error", "Please enter business type");
      } else {
        const response = await businessServices.addNewBusinessType(dataObj);
        if (response.status === 201) {
          setSearchInput("");
          createNotification("success", response.data.message);
          handleClose();
          getData(activePage, pageLimit, tokenResponse);
          document.getElementById("search").value = "";
        }
        if (response.status === 400) {
          createNotification("error", "Business type already exists");
        }
        setActivePage(1);
        getData(activePage, pageLimit, tokenResponse);
        handleClose();
        setBusinessData({ businessType: "" });
      }
    }
  };
  const handleChangeStatus = async (status) => {
    const response = await businessServices.updateStatus(status._id, status.isDeleted);
    getData(activePage, pageLimit, tokenResponse);
    if (response.data.status == 201) {
      createNotification("warning", response.data.message);
     
    } else if (response.data.status == 200) {
      createNotification("success", response.data.message);
      
    }
  };

  useEffect(async () => {
    window.scrollTo({
      behavior: "smooth",
      top: "0px",
    });
    if (searchInput) {
      setLoader(true)

      const trimSearchText = searchInput.trim();
      if (countValue) {
        const response = await businessServices.searchBusinessTypes(
          activePage,
          countValue,
          trimSearchText
        );
        setBusinessTypeData(response.data);
        setTotalCount(response.count);
        serialNumber(activePage);
      setLoader(false)

      } else {
        const response = await businessServices.searchBusinessTypes(
          activePage,
          pageLimit,
          trimSearchText
        );
        setBusinessTypeData(response.data);
        setTotalCount(response.count);
        serialNumber(activePage);
      }
    } else {
      if (countValue) {
        getData(activePage, countValue, tokenResponse);
      } else {
        if ((activePage, pageLimit, tokenResponse)) {
          getData(activePage, pageLimit, tokenResponse);
        }
      }
    }
  }, [activePage, tokenResponse, pageLimit]);
  return (
    <Layout
      notificationCount={notificationCount}
      setNotificationCount={setNotificationCount}
    >
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <SearchBar
              searchInput={searchInput}
              handleShow={handleShow}
              handleSearchBusinessType={handleSearchBusinessType}
            />
            <div className="plan-table">
              {loader ? (
                <Loader />
              ) : dataFound ? (
                <NoDataFound />
              ) : (
                <BusinessTypeTable
                  businessTypeData={businessTypeData}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  handleChangeStatus={handleChangeStatus}
                  serial_num={serialNumberVal}
                  totalCount={totalCount}
                  pageLimit={pageLimit}
                  handleCountSelection={handleCountSelection}
                  activePage={activePage}
                  changeCount={changeCount}
                  countValue={countValue}
                  isDeletedstatus={isDeletedstatus}
                  setisdeletedStatus={setisdeletedStatus}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table-pagination">
          {pagination && (
            <ReactPagination
              activePage={activePage}
              pageLimit={countValue ? countValue : pageLimit}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        <BusinessTypeModal
          handleClose={handleClose}
          show={show}
          businessData={businessData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          edit={edit}
          err={err}
          add={add}
          deleted={deleted}
          handleRemove={handleRemove}
        />
        <ToastContainer />
      </React.Fragment>
    </Layout>
  );
};

export default BusinessType;
