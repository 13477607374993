import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL

export const getAllBusinessTypes = async (pageNo, limit, token) => {
  const headerData = { Authorization: `Bearer ${token}` };
  const tokenResponse = token;
  try {
    if (tokenResponse && headerData) {
      const response = await axios.get(
        `${apiUrl}/admin/business/get/${pageNo}/${limit}`,
        { headers: { Authorization: `Bearer ${tokenResponse}` } }
      );
      if (response.status == 200) {
        return response.data;
      } else {
        throw new Error(response.message);
      }
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const editBusinessType = async (id, data) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if (token && headerData) {

      const response = await axios.put(
        `${apiUrl}/admin/business/edit/${id}`,
        data,
        { headers: headerData }
      );
      if (response.status == 200) {
        return response;
      } else {
        throw new Error(response.message);
      }
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const removeBusinessType = async (id) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if (token && headerData) {

      const response = await axios.delete(
        `${apiUrl}/admin/business/delete/${id}`,
        { headers: headerData }
      );
      if (response.status == 200) {
        return response;
      } else {
        throw new Error(response.message);
      }
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const addNewBusinessType = async (data) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if (token && headerData) {

      const response = await axios.post(
        `${apiUrl}/admin/business/create`,
        data,
        { headers: headerData }
      );
      return response;
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const searchBusinessTypes = async (pageNo, limit, text) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if (token && headerData) {

      const response = await axios.get(
        `${apiUrl}/admin/business/search?page=${pageNo}&limit=${limit}&text=${text}`,
        { headers: headerData }
      );
      if (response.status == 200) {
        return response.data;
      } else {
        throw new Error(response.message);
      }
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const updateStatus = async (id, status) => {
  try {
    const token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.put(
      `${apiUrl}/admin/business/statusChange/${id}/${status}`,
      {},
      {
        headers: headerData,
      }
    );
    if (response.status == 200) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};







export const getAllBusinessTypesForModal = async (pageNo, limit) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if (token && headerData) {

      const response = await axios.get(`${apiUrl}/admin/business/getAll`, {
        headers: headerData,
      });
      if (response.status == 200) {
        return response.data;
      } else {
        throw new Error(response.message);
      }
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};
