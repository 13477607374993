import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { createNotification } from "../../helper/notification";
import * as userService from "../../services/usersServices";
import * as importService from "../../services/importFile";

const ImportPopup = ({  handleState, handleErrorData }) => {
  const [userList, serUserList] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState([]);
  const [userFile, setUserFile] = useState();
  const [userState, setUserState] = useState("");
  const [dataState, setDataState] = useState([]);
  const [getRejectData, setGetRejectData] = useState("");
  const getuser = async () => {
    const response = await userService.getallUsers();
    serUserList(response.data);
  };
  const fileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const imageFile = e.target.files[0];
      if (!imageFile.name.match(/\.(csv)$/)) {
        createNotification("error", "Please select valid csv file.");
        return false;
      }
      setUserFile(e.target.files[0]);
    }
  };

  const stateChangeHandler = (e) => {
    setUserState(e.target.value);
    handleState(e.target.value);
  };

  const validation = () => {
    let isValid = true;
    let formError = {};
    if (!userState) {
      isValid = false;
      formError["userState"] = "Please select user";
    }
    if (!userFile) {
      isValid = false;
      formError["userFile"] = "Please Upload CSV file";
    }
    setError(formError);
    return isValid;
  };

  const processCSV = (string, delim = ",") => {
    const headers = string.slice(0, string.indexOf("\n")).split(delim);
    const rows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = rows.map((i) => {
      const values = i.split(":");
      const obj = headers.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
  };

  const handleFileImport = async (e) => {
    if (validation()) {
      e.preventDefault();
      const file = userFile;
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text);
      };
      reader.readAsText(userFile);
      const formData = new FormData();
      formData.append("userId", userState);
      formData.append("csvfile", file);
      const response = await importService.addFile(formData);
      if (response.data.status === 200) {
        createNotification("success", response.message);
        setDataState(response.data.error);
        setShow(false);
      } else {
        createNotification("error", response.message);
        setShow(false);
      }
      const resp = await importService.onRejects();
      setGetRejectData(response.data);
      handleErrorData(response.data);
    }
  };

  useEffect(() => {
    getuser();
  }, []);

  const handleShow = () => setShow(true);

  const handleCloseOnCloseButton = () => {
    setShow(false);
    setUserFile();
    setUserState("");
    setError([]);
  };
  return (
    <div className="importpopup">
      <Button variant="info" onClick={handleShow} style={{ float: "right" }}>
        Import CSV
      </Button>

      <Modal
        className="user-modal"
        show={show}
        onHide={handleCloseOnCloseButton}
      >
        <Modal.Header>
          <Modal.Title>Import CSV File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-box">
            <div className="form-filed_course">
              <div className="form-group">
                <label className="form-label">Select User</label>
                <select
                  className="input1"
                  name="userState"
                  onChange={stateChangeHandler}
                >
                  <option value="">Select User</option>
                  {userList &&
                    userList.length > 0 &&
                    userList
                      .filter((item) => item.isDeleted == false)
                      .map((item, i) => (
                        <option key={i} value={item._id}>
                          {item.firstName}
                        </option>
                      ))}
                </select>
                <span className="form-error">{error && error.userState}</span>
              </div>
              <div className="form-group">
                <label className="form-label">Upload CSV File</label>
                <input
                  name="userFile"
                  type="file"
                  id="csvFileInput"
                  accept=".csv"
                  onChange={fileChangeHandler}
                />
                <span className="form-error">{error && error.userFile}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOnCloseButton}>
            Close
          </Button>
          <Button onClick={handleFileImport} variant="primary">
            Import
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default ImportPopup;
