import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL

export const getAllusers = async (pageNumber, pageLimit) => {
  try {
    let token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.get(
      `${apiUrl}/admin/getAllUsers/${pageNumber}/${pageLimit}`,
      {
        headers: headerData,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};


export const getUserWithId = async (id) => {
  try {
    let token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.get(
      `${apiUrl}/admin/users/getbyid/${id}`,
      {
        headers: headerData,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getuserByid = async (pageNumber, pageLimit) => {
  try {
    let token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.get(
      `${apiUrl}/admin/getAllUsers/${pageNumber}/${pageLimit}`,
      {
        headers: headerData,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const EditUSers = async (id, obj) => {
  try {
    let token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.put(
      `${apiUrl}/admin/profileUpdate/${id}`,
      obj,
      {
        headers: headerData,
      }
    );

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const changeStatus = async (id, obj) => {
  let token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  const response = await axios.patch(
    `${apiUrl}/admin/change-status/${id}/${obj}`,

    {
      headers: headerData,
    }
  );
  return response.data;
};

export const getAllSearchuser = async (pageNumber, pageLimit, text) => {
  try {
    let token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.get(
      `${apiUrl}/admin/getAllSearchUsers/${pageNumber}/${pageLimit}/${text}`,
      {
        headers: headerData,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getallUsers = async () => {
  try {
    let token = localStorage.getItem("admin_token");
    const headerData = { Authorization: `Bearer ${token}` };
    const response = await axios.get(
      `${apiUrl}/admin/users/getAllUserOnly`,
      {
        headers: headerData,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const changeLoginStatus = async (obj) => {
  let token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  const response = await axios.put(
    `${apiUrl}/frontend/user/loginStatus`,
    obj,

    {
      headers: headerData,
    }
  );
  return response.data;
};
