import axios from "axios";
import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import * as userServices from "../../services/usersServices";
import { createNotification } from "../../helper/notification";
import { ToastContainer } from "react-toastify";
import "./profile.scss";
import { useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Profile = ({notificationCount,setNotificationCount}) => {
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  let handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser(() => {
      return {
        ...user,
        [name]: value,
      };
    });
  };

  const { firstName, lastName, email } = user;
  const handleEdit = async (e) => {
    const formValidation = () => {
      let formErrors = {};
      let isValid = true;
      if (!firstName) {
        isValid = false;
        formErrors["firstName"] = "FirstName is Required";
      }
      if (!lastName) {
        isValid = false;
        formErrors["lastName"] = "LastName is Required";
      }

      setError(formErrors);
      return isValid;
    };

    if (formValidation()) {
      try {
        const userId = localStorage.getItem("userid_");
        const response = await userServices.EditUSers(userId, user);
        if (response.status == 200) {
          createNotification("success", response.data.message);
          setTimeout(() => {
            navigate("/users");
          }, 2000);
        }
      } catch (err) {
        createNotification("error", err.message);
        console.log(err.message);
      }
    }
  };

  const getAdmin = async () => {
    try {
      let token = localStorage.getItem("admin_token");
      let adminId = localStorage.getItem("userid_");

      const data = await axios.get(
        `${apiUrl}/admin/getUser/${adminId}`,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUser(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => getAdmin(), []);

  return (
    <>
     <Layout notificationCount={notificationCount}
    setNotificationCount={setNotificationCount}>
        <div className="profile-login-layout">
          <h2>Edit Profile</h2>
          <div className="profilelogin">
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                value={firstName}
                placeholder="First Name"
                onChange={handleChange}
              />
              <span className="error">{error.firstName}</span>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                onChange={handleChange}
              />
              <span className="error">{error.lastName}</span>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="email"
                disabled
                value={email}
                placeholder="email"
                onChange={handleChange}
              />
            </div>
            <button className="button" onClick={handleEdit}>
              Save Changes
            </button>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </>
  );
};

export default Profile;
