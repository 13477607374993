import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL

export const getAllBusinessServices = async (pageNo, limit, token) => {
  const headerData = { Authorization: `Bearer ${token}` };
  const tokenResponse = token;

  try {
    if(tokenResponse && headerData){
    const response = await axios.get(
      `${apiUrl}/admin/businessService/get/${pageNo}/${limit}`,
      { headers: { Authorization: `Bearer ${tokenResponse}` } }
    );
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const editBusinessService = async (id, data) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
   if(token && headerData){
    const response = await axios.put(
      `${apiUrl}/admin/businessService/edit/${id}`,
      data,
      { headers: headerData }
    );
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
   }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const removeBusinessService = async (id) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if(token && headerData){
    const response = await axios.delete(
      `${apiUrl}/admin/businessService/remove/${id}`,
      { headers: headerData }
    );
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const addNewBusinessService = async (data) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if(token && headerData){
    const response = await axios.post(
      `${apiUrl}/admin/businessService/create`,
      data,
      { headers: headerData }
    );
    return response;
    }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};

export const searchBusinessServices = async (pageNo, limit, text) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    if(token && headerData){
    const response = await axios.get(
      `${apiUrl}/admin/businessService/search?page=${pageNo}&limit=${limit}&text=${text}`,
      { headers: headerData }
    );
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  }
  } catch (err) {
    return {
      data: "",
      status: 400,
      message: err.message,
    };
  }
};
