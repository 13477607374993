import React,{useState,useEffect} from "react";
import { Dropdown, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { MdMenu ,MdOutlineNotificationsNone} from "react-icons/md";
import NotificationAdminPopup from "../notification/notificationPopUp";
import * as notificationService from "../services/notificationService";

import axios from "axios";







function Header(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const getNotification = async () => {
    const response = await notificationService.getNotification();
    if (response.status == 200) {
      props.setNotificationCount(
        response.data.data.filter((val) => val.status === "unread").length
      );
    } else {
      console.log("error");
    }
  };
  const getAdmin = async () => {
    try {
      let token = localStorage.getItem("admin_token");
      let adminId = localStorage.getItem("userid_");
  
      const data = await axios.get(
        `${apiUrl}/admin/getUser/${adminId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUser(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => getAdmin(), []);

  useEffect(() => {
    getNotification();
  }, []);
  const logoutUser = () => {
    localStorage.clear();
    navigate("/");
  };
  const openNotification = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add('open-notification');
    } else {
      document.body.classList.remove('close-notification');
    }
  };
  const closeNotification = () => {
    setIsOpen(false);
    if (!isOpen) {
      document.body.classList.add('open-notification');
    } else {
      document.body.classList.remove('close-notification');
    }
  };
  return (
    <>
      <div className="header-bar-wrapper">
        <nav className="navbar-breadcrumb col-xl-12 col-12 d-flex">
          <div className="navbar-links-wrapper">
            <Button className="menubarbtn" onClick={props.menuToggle}>
              <MdMenu />
            </Button>
          </div>

          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <Button className="notification-btn" onClick={openNotification}><MdOutlineNotificationsNone/><span className="notification-value">{props.notificationCount}</span></Button>
            <ul className="navbar-nav mr-lg-2">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
               {user?.firstName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to="/profile"
                    >
                      Profile
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to="/changePassword"
                    >
                      Change Password
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>{" "}
      <NotificationAdminPopup isOpen={isOpen} closeNotification={closeNotification}/> 
    </>
  );
}

export default Header;
