import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import { useParams, useNavigate } from "react-router-dom";
import { getUserWithId } from "../../services/usersServices";
const UserDetailInformation = ({ notificationCount, setNotificationCount }) => {
  const { id } = useParams();
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getUserDetail(id);
  }, [id]);
  const getUserDetail = async (id) => {
    const response = await getUserWithId(id);

    if (response) {
      setUserData(response.data[0]);
    }
  };

  const handleButtonClick = () => {
    navigate('/users');
  };
  console.log(userData,"userDatauserDatauserData")

  return (
    <>
      <Layout
        notificationCount={notificationCount}
        setNotificationCount={setNotificationCount}
      >
        <React.Fragment>
          <div className="warmup-content-wrappper">
            <div className="plan-wrapper">
              <div class="plan-table-heading d-flex justify-content-between">
                <h3>User Information</h3>
                <button className="back-btn" onClick={handleButtonClick}>
                  Back
                </button>
              </div>
              <div className="user-detail-wrapper">
                <div className="profile-information">
                  <div className="personal-info">
                    <ul>
                      <li>
                        <span className="label">Name:</span>
                        <span className="value">{userData?.firstName}</span>
                      </li>
                      <li>
                        <span className="label">Business Name:</span>
                        <span className="value">{userData?.businessName}</span>
                      </li>

                      {/* <li><span className="label">Subscription Plan</span>
                                <span className="value">{userData?.planDeatils?.planName}</span>
                            </li> */}

                      <li>
                        <span className="label">Business Type:</span>
                        <span className="value">
                          {userData?.businessType?.map((data, index, array) => {
                            return `${data?.businessType}${
                              index < array.length - 1 ? ", " : ""
                            }`;
                          })}
                        </span>
                      </li>
                      <li>
                        <span className="label">Email:</span>
                        <span className="value">{userData?.email}</span>
                      </li>
                      <li>
                        <span className="label">Mobile:</span>
                        <span className="value">{userData?.mobile}</span>
                      </li>
                      <li>
                        <span className="label">Business Phone:</span>
                        <span className="value">{userData?.phone}</span>
                      </li>
                      <li>
                        <span className="label">Current Plan:</span>
                        <span className="value">
                          {userData.paymentStatus == "1"
                            ? userData.planDeatils?.planName
                            : "Not buy"}
                        </span>
                      </li>

                      <li>
                        <span className="label">Residence:</span>
                        <span className="value">{userData?.state}</span>
                      </li>
                      <li>
                        <span className="label">Referred:</span>
                        <span className="value">{userData?.reffered}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Layout>
    </>
  );
};
export default UserDetailInformation;
