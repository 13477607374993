import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";

// import { useNavigate } from "react-router-dom";
const UserTable = ({
  usersData,
  handleDelete,
  handleEdit,
  handleChangeStatus,
  serial_num,
  totalCount,
  pageLimit,
  activePage,
  changeCount,
  countValue,
}) => {
  const navigate = useNavigate();

  let arr = [];
  if (totalCount >= countValue) {
    let count = Math.ceil(totalCount / 5);
    count = count * pageLimit;
    let newCount = count / 2;
    arr.push(newCount);
    arr.push(count);
  } else {
    let newGeneratedValue1 = 5;
    arr.push(newGeneratedValue1);
  }
  const handleUserDetail = (id) => {

    navigate(`/userdetail/${id}`);
  };
  const UserDataFunction = () => {
    const usersAllData =
      usersData &&
      usersData.length > 0 &&
      usersData.map((user, index) => (
        <tr key={index}>
          <td>{serial_num + index + 1}</td>
          <td>{user.firstName}</td>
          <td>{user.mobile}</td>
          <td>{user.phone}</td>
          <td>{user.paymentStatus === "1" ? user.planDeatils?.planName : "Not buy"}</td>
          <td>{user.reffered}</td>
          <td>{user.state}</td>
          <td>{user.businessName}</td>
          <td>
            <ul>
              {user.businessType.map((type, i) => (
                <li key={i}>{type.businessType}</li>
              ))}
            </ul>
          </td>
          <td>{user.email}</td>
          <td>
            <Switch
              onChange={() => handleChangeStatus(user)}
              checked={!user?.isDeleted}
              width={60}
              onColor="#212529"
            />
          </td>
          <td>
            <i
              className="fa fa-pencil"
              aria-hidden="true"
              style={{
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => handleEdit(user, user._id)}
            ></i>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{
                marginLeft: "10px",
                cursor: "pointer",
              }}
              onClick={() => handleDelete(user._id)}
            ></i>
            <i
              className="fa fa-eye"
              aria-hidden="true"
              style={{
                marginLeft: "15px",
                cursor: "pointer",
              }}
              onClick={() => handleUserDetail(user._id)}
            ></i>
          </td>
        </tr>
      ));

    return usersAllData;
  };









  return (
    <>
      <Table striped bordered hover responsive>
        <thead style={{ position: "sticky", top: "-1px", zIndex: "999999" }}>
          <tr>
            <th style={{ position: "sticky" }}>Sr No.</th>
            <th style={{ position: "sticky" }}>Name</th>
            <th style={{ position: "sticky" }}>Mobile</th>
            <th style={{ position: "sticky" }}>Business Phone</th>
            <th style={{ position: "sticky" }}>Current Plan</th>
            <th style={{ position: "sticky" }}>Referred</th>
            <th style={{ position: "sticky" }}>Residence</th>
            <th style={{ position: "sticky" }}>Business Name</th>
            <th style={{ position: "sticky" }}>Business Type</th>
            <th style={{ position: "sticky" }}>Email</th>
            <th style={{ position: "sticky" }}>Status</th>
            <th style={{ position: "sticky" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {UserDataFunction()}
        </tbody>
      </Table>
      {activePage == 1 && (
        <>
          <span>Show</span>
          <select
            name="count"
            value={countValue}
            onChange={(e) => changeCount(e)}
            style={{ width: "100px" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <span>entries</span>
        </>
      )}
    </>
  );
};

export default UserTable;
