import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

const ImportTable = ({  errorData }) => {
  const ImportTableFunction = () => {
    const AllErrors =
    errorData &&
    errorData.length > 0 &&
    errorData.map((val , index) => {
          return (
            <>
                 <tr>
                    <td>{index + 1}</td>
                    <td>{val}</td>
                  </tr>
            </>
          );
        });
    return AllErrors;
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Sr. No</th>

            <th>Errors</th>
          </tr>
        </thead>
        <tbody>
          {ImportTableFunction()}
        </tbody>
      </Table>
    </div>
  );
};
export default ImportTable;
