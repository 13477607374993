import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BusinessServiceTable = ({
  businessServiceData,
  handleDelete,
  handleEdit,
  serial_num,
  totalCount,
  pageLimit,
  activePage,
  handleRemoveData,
  countValue,
  changeCount,
}) => {
  const navigate = useNavigate();

  let arr = [];
  if (totalCount >= countValue) {
    let count = Math.ceil(totalCount / 5);
    count = count * pageLimit;
    let newCount = count / 2;
    arr.push(newCount);
    arr.push(count);
  } else {
    let newGeneratedValue1 = 5;
    arr.push(newGeneratedValue1);
  }

 

  const handleParentDelete = (id, e) => {
    handleRemoveData(id, e, "notShow");
  };
  const handleClickUserdetail = (id) =>{
    navigate(`/userdetail/${id}`);
    
   
  }

  const businessServicesData = () => {
    const serviceData =
    businessServiceData &&
    businessServiceData.length > 0 &&
    businessServiceData.map((business , index , e) => {
      console.log(business,"LLLLLLLLLLLLLLL")
          return (
            <>
              <tr key={index}>
                <td>{serial_num + index + 1}</td>
                <td>{business.service}</td>
                {/* <td>{business?.addedBy?.firstName}</td> */}
                <td>
                  {business.businessTypeId.length > 0
                    ? business.businessTypeId.map((type, i) => (
                        <li key={i}>{type.businessType}</li>
                      ))
                    : handleParentDelete(business._id, e)}
                </td>
                <td onClick={() => business?.addedBy?.role != 1 && handleClickUserdetail(business?.addedBy?._id)} className={business?.addedBy?.role !== 1 ? 'clickable-td' : ''}>
  {business?.addedBy?.firstName}
  {business?.addedBy?.role === 1 ? " (Admin)" : ""}
</td>
                <td>
                  <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(business)}
                  ></i>
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleDelete(business._id, e, "show")}
                  ></i>
                </td>
              </tr>
            </>
          );
        });
    return serviceData;
  };
  return (
    <>
      <Table striped bordered hover responsive>
        <thead style={{position:"sticky", top:"-1px"}}>
          <tr>
            <th style={{position:"sticky", }}>Sr No.</th>
            <th style={{position:"sticky",}}>Business Service</th>
            {/* <th style={{position:"sticky",}}>User Name</th> */}
            <th style={{position:"sticky",}}>Business Type</th>
            <th style={{position:"sticky",}}>Added By</th>
             <th style={{position:"sticky",}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {businessServicesData()}
        </tbody>
      </Table>
      {activePage == 1 && (
        <>
          <span>Show</span>
          <select
            name="count"
            value={countValue}
            onChange={(e) => changeCount(e)}
            style={{ width: "100px" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <span>entries</span>
        </>
      )}
    </>
  );
};

export default BusinessServiceTable;
