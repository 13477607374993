import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <>
    <div className="loader">
     <div className="loading">
    <div></div>
    <div></div>
  </div>  
  </div>
    </>
  );
};
export default Loader;