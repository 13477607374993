window.process = {};
import React,{useState,useEffect} from "react";
import "./App.css";
import "./assets/css/style.css";
import "./assets/vendors/typicons/typicons.css";
import "./assets/vendors/font-awesome/css/font-awesome.min.css";
import "./assets/scss/main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login/login";
import Profile from "./pages/profile";
import ResetPassword from "./pages/resetPassword/resetPassword";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./hoc/privateRouter";
import * as notificationService from "./services/notificationService";
import ChangePassword from "./pages/profile/changePassword";
import ProtectedRoutes from "./hoc/protectedRouters";
import Users from "./pages/users/users";
import BusinessType from "./pages/businessType/businessType";
import BusinessServices from "./pages/businessServices/businessServices";
import Importfile from "./pages/importfile/importfile";
import { getTokens } from "./helper/firebase";
import { onMessageListener } from "./helper/firebase";
import Notifications from "./pages/notifications/notifications";
import UserDetailInformation from "./pages/userdetail";
const App = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationLIst] = useState();
  const [tokenFound, setTokenFound] = useState("");
  const [fcmToken, setFCMToken] = useState(""); 
  onMessageListener()
  .then((payload) => {
    setNotification({
      title: payload.notification.title,
      body: payload.notification.body,
    });
    getNotification();
  })
  .catch((err) => console.log("failed: ", err));

useEffect(() => {
  getNotification();
}, []);

useEffect(() => {
  let data;
  async function tokenFunc() {
    data = await getTokens(setTokenFound);
    if (data) {
      setFCMToken(data);
    }
    return data;
  }
  tokenFunc();
}, [setTokenFound]);

const getNotification = async () => {
  const response = await notificationService.getNotification();
  if (response.status == 200) {
    setNotificationLIst(response.data.data);
    setNotificationCount(
      response.data.data.filter((val) => val.status === "unread").length
    );
  } else {
    console.log("error");
  }
};

  return (
    <div>
      <Router>
        <Routes>
          <Route
            isAuth={true}
            path="/profile"
            element={
              <PrivateRoute>
                <Profile notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}/>
              </PrivateRoute>
            }
          />
           <Route
            isAuth={true}
            path="/userdetail/:id"
            element={
              <PrivateRoute>
                <UserDetailInformation  notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}/>
              </PrivateRoute>
            }
          />
           <Route
            isAuth={true} 
            path="/users"
            element={
              <PrivateRoute>
                <Users notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}/>
              </PrivateRoute>
            }
          />
          <Route
            isAuth={true}
            path="/businessType"
            element={
              <PrivateRoute>
                <BusinessType notificationCount={notificationCount}
                setNotificationCount={setNotificationCount} />
              </PrivateRoute>
            }
          />
          <Route
            isAuth={true}
            path="/notification"
            element={
              <PrivateRoute>
                <Notifications  notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}/>
              </PrivateRoute>
            }
          />
          <Route
            isAuth={true}
            path="/businessServices"
            element={
              <PrivateRoute>
                <BusinessServices  notificationCount={notificationCount}
                setNotificationCount={setNotificationCount} />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoutes>
                <Login  />
              </ProtectedRoutes>
            }
          ></Route>

          <Route
            path="/forgot/resetPassword/:token"
            element={<ResetPassword  />}
          />
          <Route
            isAuth={true}
            path="/fileImport"
            element={
              <PrivateRoute>
                <Importfile notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}/>
              </PrivateRoute>
            }
          ></Route>
          <Route path="/changepassword" element={<ChangePassword  notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
