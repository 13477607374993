import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../../layout/layout";
import ReactPagination from "../Pagination/reactPagination";
import * as UserServices from "../../services/userServices";
import { ToastContainer } from "react-toastify";
import SearchBar from "../search/searchBar";
import UserTable from "../../table/userTable";
import { createNotification } from "../../helper/notification";
import UserModal from "../../modals/userModal";
import * as userBusinessServices from "../../services/userBusinessServices";
import states from "../../config/state.json";
import NoDataFound from "../dataFound/noDataFound";
import Loader from "../../loader/loader";
const Users = ({ notificationCount, setNotificationCount }) => {
  const [usersData, setUsersData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState();
  const [edit, setEdit] = useState(false);
  const [err, setErr] = useState({});
  const [bussType, setBussType] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [add, setAdd] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [userState, setUserState] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [paginate, setPaginate] = useState(false);
  const [items, setItems] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [serialNumberVal, setSerialNumberVal] = useState();
  const [countValue, setCountValue] = useState(5);

  const [user, setUser] = useState({
    email: "",
    businessName: "",
    firstName: "",
    phone: "",
    mobile: "",
    state: "",
    reffered: "",
  });
  const { email, businessName, firstName, phone, mobile, state, reffered } =
    user;

  const tokenResponse = useSelector((state) => state.auth.token);

  const serialNumber = (activePage) => {
    let current_pagenum = activePage;
    let total_records_per_page = countValue ? countValue : pageLimit;
    let serial_num = total_records_per_page * (current_pagenum - 1);
    setSerialNumberVal(serial_num);
  };

  useEffect(() => {
    serialNumber();
  }, [activePage]);

  useEffect(() => {
    setUserState(states.states);
  }, []);

  const handleCountSelection = async (data) => {
    if (searchInput) {
      const trimText = searchInput.trim();
      const response = await UserServices.getSearchedUsers(1, data, trimText);
      setUsersData(response.data.data);
      setTotalCount(response.data.count);
    } else {
      getUsersData(activePage, data, tokenResponse);
      setPageLimit(data);
    }
  };

  const changeCount = (e) => {
    let value = e.target.value;
    setCountValue(value);
    handleCountSelection(value);
  };
  const getAllbusinessTypes = async () => {
    let arr = [];
    const response = await userBusinessServices.getAllbussTypes();
    if (response.status === 200) {
      if (response?.data.length > 0) {
        response.data.map((resp) => {
          return arr.push({ type: resp.businessType, id: resp._id });
        });
      }
      setBussType(arr);
    }
  };

  useEffect(() => {
    getAllbusinessTypes();
  }, []);

  const handleClose = () => {
    setShow(false);
    setUser({});
    setErr([]);
    setItems([]);
    setTimeout(() => {
      setEdit(false);
      setAdd(false);
      setDeleted(false);
    }, 500);
  };

  const handleShow = () => {
    setShow(true);
    setAdd(true);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchInput(text);
    if (text !== " ") {
      if (text.length > 0) {
        let activePage = 1;
        setActivePage(activePage);
        const response = await UserServices.getSearchedUsers(
          activePage,
          pageLimit,
          text
        );

        if (response.data.count <= 5) {
          setPaginate(false);
        }
        if (response?.data?.data?.length > 0) {
          setUsersData(response.data.data);
          setTotalCount(response.data.count);
          setPaginate(true);
          serialNumber(activePage);
          setDataFound(false);
        } else {
          setDataFound(true);
          setUsersData([]);
          setTotalCount(0);
          setPaginate(false);
        }
      }

      if (text == "") {
        setDataFound(false);
        if (countValue) {
          let pageActive = 1;
          setActivePage(pageActive);
          getUsersData(activePage, countValue, tokenResponse);
          setPaginate(true);
        } else {
          if ((activePage, pageLimit, tokenResponse)) {
            getUsersData(activePage, pageLimit, tokenResponse);
          }
        }
        setPaginate(true);
      }

      if (usersData == []) {
        setUsersData([]);
        setPaginate(false);
      }
    }
  };

  const handleDelete = async (id) => {
    setDeleted(true);
    setAdd(false);
    setEdit(false);
    setDeleteId(id);
    handleShow();
  };

  const handleRemove = async () => {
    const id = deleteId;
    const response = await UserServices.removeUser(id);
    if (response.status == 200) {
      createNotification("success", response.data.message);
      handleClose();
      getUsersData(1, pageLimit, tokenResponse);
      document.getElementById("search").value = "";
    }
  };

  const handleEdit = async (user, id) => {
    handleShow();
    setEdit(true);
    const {
      firstName,
      mobile,
      phone,
      reffered,
      state,
      businessName,
      businessType,
      email,
      password,
    } = user;
    setEditId(id);
    setUser({
      firstName,
      mobile,
      phone,
      reffered,
      state,
      businessName,
      email,
      password,
    });
    setItems(businessType);
    setDeleted(false);
  };

  const validate = () => {
    let isValid = true;
    const formError = {};
    const regex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const phoneNo = /^\d{10,15}$/;

    if (!email) {
      isValid = false;
      formError["email"] = "Email is required";
    } else if (!regex.test(email)) {
      isValid = false;
      formError["email"] = "Please enter a valid email address";
    }
    if (!firstName) {
      isValid = false;
      formError["firstName"] = "Name is required";
    } else if (firstName.trim() === "") {
      isValid = false;
      formError["firstName"] = "Please enter name";
    }
    if (!mobile) {
      isValid = false;
      formError["mobile"] = "Mobile number is required";
    } else if (!phoneNo.test(mobile.replace(/-/g, ""))) {
      isValid = false;
      formError["mobile"] = "Mobile number must be of 10-12 digits";
    }
    if (!phone) {
      isValid = false;
      formError["phone"] = "Phone number is required";
    } else if (!phoneNo.test(phone.replace(/-/g, ""))) {
      isValid = false;
      formError["phone"] = "Phone number must be of 10-12 digits";
    }
    if (!state) {
      isValid = false;
      formError["state"] = "Residence is required";
    }
    if (!items.length) {
      isValid = false;
      formError["items"] = "Business Type is required";
    }
    if (!businessName) {
      isValid = false;
      formError["businessName"] = "Business Name is required";
    } else if (businessName.trim() === "") {
      isValid = false;
      formError["businessName"] = "Please enter business name";
    }
    if (!reffered) {
      isValid = false;
      formError["reffered"] = "Referral is required";
    }
    setErr(formError);
    return isValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      let arr = [];
      items.map((item) => arr.push(item.id));
      let data = {
        businessType: arr,
        businessName: user.businessName.trim(),
        firstName: user.firstName.trim(),
        email: user.email,
        mobile: user.mobile,
        password: user.password,
        phone: user.phone,
        reffered: user.reffered,
        state: user.state,
      };
      const response = await UserServices.postUsersData(data, tokenResponse);
      if (response.status == 201) {
        createNotification("success", response.data.message);
        handleClose();
        getUsersData(activePage, pageLimit, tokenResponse);
        document.getElementById("search").value = "";
      } else {
        createNotification("error", response.data.message);
      }

      setSearchText("");
      setCountValue("");
      setActivePage(1);
      getUsersData(activePage, pageLimit, tokenResponse);
      handleClose();
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    const password = Math.floor(Math.random() * 1000000 + 6);

    if (name === "phone" || name === "mobile") {
      const formattedNumber = value.replace(/[^0-9]/g, "");
      if (formattedNumber.length > 12) {
        formattedNumber = formattedNumber.slice(0, 12);
      }
      if (formattedNumber.length >= 10) {
        const firstThreeDigits = formattedNumber.slice(0, 3);
        const nextThreeDigits = formattedNumber.slice(3, 6);
        const remainingDigits = formattedNumber.slice(6);

        const formattedPhoneNumber = `${firstThreeDigits}-${nextThreeDigits}-${remainingDigits}`;

        setUser((prevUser) => ({
          ...prevUser,
          [name]: formattedPhoneNumber,
        }));
      } else {
        setUser((prevUser) => ({
          ...prevUser,
          [name]: formattedNumber,
        }));
      }
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
        password: add ? password : prevUser.password,
      }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validate()) {
      const response = await UserServices.editUserData(editId, user);
      if (response.status == 200) {
        setSearchInput("");
        createNotification("success", response.message);
        handleClose();
        getUsersData(activePage, pageLimit, tokenResponse);
      } else {
        createNotification("error", response.message);
      }
      getUsersData(activePage, pageLimit, tokenResponse);
      handleClose();
      setTimeout(() => {
        setEdit(false);
      }, 1000);
    }
  };

  const handleChangeStatus = async ({ _id, isDeleted }) => {
    const checkDeactivateStatus = await UserServices.getsingleUser(_id);

    if (checkDeactivateStatus?.data[0]?.isActivateAccount == true && isDeleted == false) {
      createNotification("warning", "User is already deactivate by himself");
    } else {
      const response = await UserServices.updateStatus(_id, isDeleted);
      getUsersData(activePage, pageLimit, tokenResponse);
      if (response.data.status == 201) {
        createNotification("warning", response.data.message);
      } else if (response.data.status == 200) {
        createNotification("success", response.data.message);
      }
    }
  };

  const getUsersData = async (activePage, pageLimit, tokenResponse) => {
    setLoader(true);
    const response = await UserServices.getData(
      activePage,
      pageLimit,
      tokenResponse
    );
    if (response.data.length > 0) {
      setDataFound(false);
      setPaginate(true);
    } else {
      setDataFound(true);
      setPaginate(false);
    }
    if (response.totalCount >= 0) {
      setLoader(false);
      setUsersData(response.data);
      serialNumber(activePage);
      setTotalCount(response.totalCount);
    } else if (response.totalCount == 0) {
      setLoader(false);
    }
  };
  useEffect(async () => {
    window.scrollTo({
      behavior: "smooth",
      top: "0px",
    });
    if (searchInput) {
      setLoader(true);

      const trimsearchText = searchInput.trim();
      if (countValue) {
        const response = await UserServices.getSearchedUsers(
          activePage,
          countValue,
          trimsearchText
        );
        setUsersData(response.data.data);
        setTotalCount(response.data.count);
        serialNumber(activePage);
        setLoader(false);
      } else {
        const response = await UserServices.getSearchedUsers(
          activePage,
          pageLimit,
          trimsearchText
        );
        setUsersData(response.data.data);
        setTotalCount(response.data.count);
        serialNumber(activePage);
      }
    } else {
      if (countValue) {
        getUsersData(activePage, countValue, tokenResponse);
      } else {
        if ((activePage, pageLimit, tokenResponse)) {
          getUsersData(activePage, pageLimit, tokenResponse);
        }
      }
    }
  }, [activePage, tokenResponse, pageLimit]);
  return (
    <Layout
      notificationCount={notificationCount}
      setNotificationCount={setNotificationCount}
    >
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <SearchBar
              searchInput={searchInput}
              handleSearch={handleSearch}
              handleShow={handleShow}
            />
            <div className="plan-table">
              {loader ? (
                <Loader />
              ) : dataFound ? (
                <NoDataFound />
              ) : (
                <UserTable
                  usersData={usersData}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  handleChangeStatus={handleChangeStatus}
                  serial_num={serialNumberVal}
                  totalCount={totalCount}
                  pageLimit={pageLimit}
                  handleCountSelection={handleCountSelection}
                  activePage={activePage}
                  changeCount={changeCount}
                  countValue={countValue}
                />
              )}
            </div>
          </div>
        </div>
        <ToastContainer />

        <div className="table-pagination">
          {paginate && (
            <ReactPagination
              activePage={activePage}
              pageLimit={
                parseInt(countValue)
                  ? parseInt(countValue)
                  : parseInt(pageLimit)
              }
              totalCount={totalCount}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        <UserModal
          handleClose={handleClose}
          show={show}
          user={user}
          err={err}
          changeHandler={changeHandler}
          submitHandler={submitHandler}
          edit={edit}
          handleUpdate={handleUpdate}
          bussType={bussType}
          deleted={deleted}
          handleRemove={handleRemove}
          residence={userState}
          items={items}
          setItems={setItems}
        />
      </React.Fragment>
    </Layout>
  );
};

export default Users;
