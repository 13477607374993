import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import * as authServices from "../../services/authServices";
import { createNotification } from "../../helper/notification";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePassword = ({notificationCount,setNotificationCount}) => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [error, setError] = useState([]);

  const handleChange = async () => {
    const formValidation = () => {
      const pwd = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
      );
      let formErrors = {};
      let isValid = true;
      if (!oldPassword) {
        isValid = false;
        formErrors["oldPassword"] = "Old Password is Required";
      } else if (!pwd.test(oldPassword)) {
        isValid = false;
        formErrors["oldPassword"] =
          "Your password should have at least one special charachter, digits, uppercase and lowercase charachter. Length: 8+ ch-ers.";
      }
      if (!newpassword) {
        isValid = false;
        formErrors["newpassword"] = "New Password is Required";
      } else if (!pwd.test(newpassword)) {
        isValid = false;
        formErrors["newpassword"] =
          "Your password should have at least one special charachter, digits, uppercase and lowercase charachter. Length: 8+ ch-ers.";
      }
      setError(formErrors);
      return isValid;
    };

    if (formValidation()) {
      try {
        const obj = {
          oldPassword: oldPassword,
          newpassword: newpassword,
        };
        const response = await authServices.changePassword(obj);
        if (response.data.statusCode == 200) {
          createNotification("success", response.data.message);
          setTimeout(() => {
            navigate("/users");
          }, 3000);
        } else if (response.data.statusCode == 400) {
          createNotification("error", response.data.message);
        }
      } catch (err) {
        console.log(err);
        createNotification("error", err);
      }
    }
  };

  return (
    <>
      <Layout notificationCount={notificationCount}
    setNotificationCount={setNotificationCount}>
        <div className="profile-login-layout">
          <div className="heading-tilte">
            <h3>Change Password</h3>
          </div>
          <div className="profilelogin">
            <div className="popup-form-box">
              <div className="form-filed_course">
                <label>Old Password</label>
                <input
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  placeholder="Old Password"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
                <span className="error">{error.oldPassword}</span>
              </div>

              <div className="form-filed_course">
                <label>New Password</label>

                <input
                  type="password"
                  name="newpassword"
                  value={newpassword}
                  placeholder="New Password"
                  onChange={(e) => {
                    setnewpassword(e.target.value);
                  }}
                />
                <span className="error">{error.newpassword}</span>
              </div>
            </div>

            <button className="button" onClick={handleChange}>
              Save Changes
            </button>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </>
  );
};

export default ChangePassword;
