import React, { useState, useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router";
import * as notificationService from "../services/notificationService";
import { MdClose } from "react-icons/md";
import Userimg from "../assets/images/userprofile.png";
const NotificationAdminPopup = ({ isOpen, closeNotification }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationLIst] = useState();
  const navigate = useNavigate();
  const [latestLIst, setLatestList] = useState([]);
  const getNotification = async () => {
    const response = await notificationService.getNotification();
    if (response.status == 200) {
      setNotificationLIst(response.data.data);
      setNotificationCount(
        response?.data?.data?.filter((val) => val.status === "unread").length
      );
    } else {
      console.log("error");
    }
  };

  const newData = () => {
    if (notificationList && notificationList.length > 0) {
      notificationList.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      const latest5 = notificationList.slice(0, 5);
      setLatestList(latest5);
    }
  };

  useEffect(() => {
    newData();
  }, [notificationList]);

  const handleOpenPopUp = async (item) => {
    const obj = {
      Id: item._id,
      data: "read",
    };
    navigate(`/users`);

    const response = await notificationService.updateNotification(obj);
  };

  useEffect(() => {
    getNotification();
  }, []);

    const latestLIstFunction = () => {
      const AlllatestLIst =
      latestLIst &&
      latestLIst?.length > 0 &&
      latestLIst
        .map((item) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }))
        .sort((a, b) => b.createdAt - a.createdAt).map((item) => {
          const currentDate = new Date();
          const time = item.createdAt;

          const specificDate = new Date(time);

          const timeDiffMs = currentDate - specificDate;

          const hours = Math.floor(
            timeDiffMs / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDiffMs / (1000 * 60)) % 60
          );

          let timeString;
          if (hours === 0 && minutes === 0) {
            timeString = "Just now";
          } else if (hours < 1) {
            timeString = `${minutes} minutes ago`;
          }
          else if (hours >= 24) {
        const days = Math.floor(hours / 24);
        timeString = `${days} day${days > 1 ? 's' : ''} ago`;
      }
           else {
            timeString = `${hours} hours ${minutes} minutes ago`;
          }

            return (
              <>
                            <div className="notification-lists nonreadble">
                              <div className="notification-detail">
                                <div className="desc cursor"  onClick={() => handleOpenPopUp(item)}>
                                  <span
                                    className="cursor"
                                  >
                                    <h3>{item.title}</h3>
                                  </span>
                                  <span dangerouslySetInnerHTML={{ __html:  item.text.slice(
                                    0,
                                    Math.max(
                                      item.text.indexOf("Doesn’t"),
                                    )
                                  ) }} />
                                  <ul>
                                    <li>
                                      <span>{`${timeString}`}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
              </>
            );
          });
      return AlllatestLIst;
    };




  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="notification-wrapper"
        unmountOnExit
      >
        <div className="notification-wrapper">
          <div className="notification-box">
            <div className="heading">
              <h2>Notifications</h2>{" "}
              <Button onClick={closeNotification}>
                <MdClose />
              </Button>
            </div>
            <div>
              <>
                {latestLIst?.length > 0 ? (
                  <div className="notification-list-box">
                    {latestLIstFunction()}
                  </div>
                ) : (
                  <>
                    <div className="not-notification">
                      No Notification Found
                    </div>
                  </>
                )}
              </>
            </div>
            <div className="notfiction-btn">
              <Button href="/Notification">Show All Notifications</Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
export default NotificationAdminPopup;
