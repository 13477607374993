import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL

export const addFile = async (importObj) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.post(
      `${apiUrl}/admin/users/import`,
      importObj,
      {
        headers: headerData,
      }
    );
    return {
      status: 200,
      message: response.data.message,
      data: response.data,
    };
  } catch (error) {
    return {
      status: 400,
      message: error.response.data.message,
    };
  }
};

export const onRejects = async () => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(`${apiUrl}/admin/users/getRejects`, {
      headers: headerData,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (err) {
    return {
      data: "",
      response: err.message,
      status: 400,
    };
  }
};
export const getUserById = async (id) => {
  const token = localStorage.getItem("admin_token");
  const headerData = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${apiUrl}/admin/users/getAllUserOnly`,
      {
        headers: headerData,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
