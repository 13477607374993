import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import ReactPagination from "../Pagination/reactPagination";
import * as businessServiceServices from "../../services/businessServiceServices";
import * as businessServices from "../../services/businessServices";
import SearchBar from "../search/searchBar";
import BusinessServiceTable from "./businessServiceTable";
import BusinessServiceModal from "./businessServiceModal";
import { ToastContainer } from "react-toastify";
import { createNotification } from "../../helper/notification";
// import { Oval } from "react-loader-spinner";
import NoDataFound from "../dataFound/noDataFound";
import { useSelector } from "react-redux";
import Loader from "../../loader/loader";
const BusinessServices = ({ notificationCount, setNotificationCount }) => {
  const [businessServiceData, setBusinessServiceData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState();
  const [edit, setEdit] = useState(false);
  const [err, setErr] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [dropDownData, setDropdownData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [paginate, setPaginate] = useState(true);
  const [dataFound, setDataFound] = useState(false);
  const [businessData, setBusinessData] = useState({
    businessTypeId: "",
    service: "",
  });
  const [searchInput, setSearchInput] = useState("");
  const [countValue, setCountValue] = useState(5);
  const [serialNumberVal, setSerialNumberVal] = useState();
  const [searchText, setSearchText] = useState("");

  const changeCount = (e) => {
    let value = e.target.value;
  
    setCountValue(value);
    handleCountSelection(value);

  };

  const [newItem, setNewItem] = useState([]);

  const tokenResponse = useSelector((state) => state.auth.token);

  const serialNumber = (activePage) => {
    let current_pagenum = activePage;
    let total_records_per_page = countValue ? countValue : pageLimit;
    let serial_num = total_records_per_page * (current_pagenum - 1);
    setSerialNumberVal(serial_num);
  };

  useEffect(() => {
    serialNumber();
  }, [activePage]);

  const handleCountSelection = async (data) => {
    if (searchInput) {
      const trimText = searchInput.trim();
      const response = await businessServiceServices.searchBusinessServices(
        1,
        data,
        trimText
      );
      setBusinessServiceData(response.data);
      setTotalCount(response.count);
    } else {
      getData(activePage, data, tokenResponse);
      setPageLimit(data);
    }
  };
  const pathname = window.location.pathname;

  const handleClose = () => {
    setErr([]);
    setShow(false);
    setTimeout(() => {
      setDeleted(false);
      setEdit(false);
    }, 500);
    setBusinessData({
      businessTypeId: "",
      service: "",
    });
  };

  const handleShow = () => {
    setShow(true);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const getData = async (activePage, pageLimit, tokenResponse) => {
    setLoader(true);
    const response = await businessServiceServices.getAllBusinessServices(
      activePage,
      pageLimit,
      tokenResponse
    );
    if(response.data.length > 0){
      setDataFound(false);
      setPaginate(true);
    }else 
   {
      setDataFound(true);
      setPaginate(false);
    }
    if (response.totalCount >= 0) {
      setLoader(false);
      setBusinessServiceData(response.data);
      serialNumber(activePage);
      setTotalCount(response.totalCount);
    }
    else {
      
      setLoader(false);
    }
  };

  const handleSearchBusinessServices = async (e) => {
    const text = e.target.value;
    setSearchInput(text);
    if (text !== " ") {
      if (text.length > 0) {
        let activePage = 1;
        setActivePage(activePage);
        const response = await businessServiceServices.searchBusinessServices(
          activePage,
          pageLimit,
          text
        );
        if (response.count <= 5) {
          setPaginate(false);
        }
        if (response.data.length > 0) {
          setBusinessServiceData(response.data);
          setTotalCount(response.count);
          serialNumber(activePage);
          setPaginate(true);
          setDataFound(false);
        } else {
          setDataFound(true);
          setBusinessServiceData([]);
          setTotalCount(0);
          setPaginate(false);
        }
      }

      if (text == "") {
        setDataFound(false);
        if (countValue) {
          let pageActive = 1;
          setActivePage(pageActive);
          getData(activePage, countValue, tokenResponse);
          setPaginate(true);
        } else {
          if ((activePage, pageLimit, tokenResponse)) {
            getData(activePage, pageLimit, tokenResponse);
          }
        }
        setPaginate(true);
      }
      if (businessServiceData == []) {
        setBusinessServiceData([]);
        setPaginate(false);
      }
    }
  };

  const handleDelete = (id, e, quote) => {
    e.preventDefault;
    handleShow();
    setDeleted(true);
    setEdit(false);
    setDeleteId(id);
  };

  const handleRemoveData = (id) => {
  };
  const handleRemove = async () => {
    let id = deleteId;
    const response = await businessServiceServices.removeBusinessService(id);
    if (response.message == "Business Service Deleted Successfully") {
      createNotification("success", response.message);

      if ((activePage, pageLimit, tokenResponse)) {

        getData(1, pageLimit, tokenResponse);
      }
      handleClose();
    }
  };

  const handleEdit = (business) => {
    handleShow();
    setEdit(true);
    setEditId(business._id);
    setDeleted(false);
    setBusinessData({
      businessTypeId: business.businessTypeId,
      service: business.service,
    });
  };
  const handleUpdate = async () => {
    setDeleted(false);
    if (validation()) {
      let arr = [];
      newItem?.map((item) => arr.push(item.id));
      let data = {
        ...newItem,
        businessTypeId: arr,
        service: businessData.service,
      };
      const response = await businessServiceServices.editBusinessService(
        editId,
        data
      );
      if (response.success == true) {
        setSearchInput("");
        createNotification("success", response.message);
      } else {
        createNotification("error", "Business Services Already exist");
      }
      getData(activePage, pageLimit, tokenResponse);
      handleClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = e.target.validity.valid ? value : undefined;
    if (data !== undefined) {
      setBusinessData({
        ...businessData,
        [name]: value,
      });
    }
  };

  const validation = () => {
    const { businessTypeId, service } = businessData;
    const Error = {};
    let isValid = true;
    if (!businessTypeId?.length) {
      isValid = false;
      Error["businessTypeId"] = "Business type is required";
    }
    if (!service) {
      isValid = false;
      Error["service"] = "Business service is required";
    } else if (service.trim() === "") {
      isValid = false;
      Error["service"] = "Please enter business service";
    }

    setErr(Error);
    return isValid;
  };

  const handleSubmit = async () => {
    setDeleted(false);
    if (validation()) {
      let arr = [];
      businessData?.businessTypeId?.map((item) => arr.push(item.id));
      let data = {
        businessTypeId: arr,
        service: businessData.service,
      };
      const response = await businessServiceServices.addNewBusinessService(
        data
      );
      if (response.data.status === 200) {
        createNotification("success", response.data.message);
      } else {
        createNotification("error", response.data.message);
      }
      getData(activePage, pageLimit, tokenResponse);
      let pageActive = 1;
      setActivePage(pageActive);
      handleClose();
      setBusinessServiceData({ businessTypeId: "", service: "" });
    }
  };

  const getBusinessDropDownData = async () => {
    const response = await businessServices.getAllBusinessTypesForModal();
    let arr = [];   
      if (response?.data?.length > 0) {
        response.data.map((resp) => {
          return arr.push({ type: resp.businessType, id: resp._id });
        });
      }
      setDropdownData(arr);
  };

  useEffect(() => {
    getData(activePage, pageLimit, tokenResponse);
    getBusinessDropDownData();
  }, [tokenResponse]);

  useEffect(async () => {
    window.scrollTo({
      behavior: "smooth",
      top: "0px",
    });
    if (searchInput) {
      setLoader(true)

      const trimText = searchInput.trim();
      if (countValue) {
        
        const response = await businessServiceServices.searchBusinessServices(
          activePage,
          countValue,
          trimText
        );
        setBusinessServiceData(response.data);
        setTotalCount(response.count);
        serialNumber(activePage);
      setLoader(false)

      } else {
        const response = await businessServiceServices.searchBusinessServices(
          activePage,
          pageLimit,
          trimText
        );
        setBusinessServiceData(response.data);
        setTotalCount(response.count);
        serialNumber(activePage);
      }
    } else {
      if (countValue) {
        getData(activePage, countValue, tokenResponse);
      } else {
        if ((activePage, pageLimit, tokenResponse)) {
          getData(activePage, pageLimit, tokenResponse);
        }
      }
    }
  }, [activePage, tokenResponse, pageLimit]);

  return (
    <Layout
      notificationCount={notificationCount}
      setNotificationCount={setNotificationCount}
    >
      <React.Fragment>
        <div className="warmup-content-wrappper">
          <div className="plan-wrapper">
            <SearchBar
              searchInput={searchInput}
              handleShow={handleShow}
              handleSearchBusinessServices={handleSearchBusinessServices}
            />
            <div className="plan-table">
              {loader == true ? (
                <Loader />
              ) : dataFound ? (
                <NoDataFound />
              ) : (
                <BusinessServiceTable
                  businessServiceData={businessServiceData}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  serial_num={serialNumberVal}
                  totalCount={totalCount}
                  pageLimit={pageLimit}
                  handleCountSelection={handleCountSelection}
                  activePage={activePage}
                  handleRemoveData={handleRemoveData}
                  changeCount={changeCount}
                  countValue={countValue}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table-pagination">
          {paginate && (
            <ReactPagination
              activePage={activePage}
              pageLimit={countValue ? countValue : pageLimit}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        <BusinessServiceModal
          handleClose={handleClose}
          show={show}
          businessData={businessData}
          setBusinessData={setBusinessData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          edit={edit}
          err={err}
          dropDownData={dropDownData}
          deleted={deleted}
          handleRemove={handleRemove}
          setNewItem={setNewItem}
          newItem={newItem}
        />
        <ToastContainer />
      </React.Fragment>
    </Layout>
  );
};

export default BusinessServices;
