import React from "react";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import logo from "../assets/images/adminlogo.png";
import clogo from "../assets/images/clogo.png";
import { BiUserCircle } from "react-icons/bi";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { CgImport } from "react-icons/cg";
import "react-pro-sidebar/dist/css/styles.css";
const NavigationMenu = (props) => {
  return (
    <>
      <div className="sidebarmenubar">
        <div className="sidebarmenulist">
          <ProSidebar className={props.menuCollapse ? null : "collapsed"}>
            <SidebarHeader>
              {props.menuCollapse ? (
                <div className="logo-field">
                  <img src={logo} alt="logo" />
                </div>
              ) : (
                <img src={clogo} alt="logo" />
              )}
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <MenuItem
                  active={window.location.pathname === "/users"}
                  icon={<BiUserCircle />}
                >
                  Users
                  <Link to="/users" />
                </MenuItem>
              </Menu>
              <Menu iconShape="square">
                <MenuItem icon={<MdOutlineBusinessCenter />}>
                  Business-Type
                  <Link to="/businessType" />
                </MenuItem>
              </Menu>
              <Menu iconShape="square">
                <MenuItem icon={<MdOutlineBusinessCenter />}>
                  Business-Services
                  <Link to="/businessServices" />
                </MenuItem>
              </Menu>
              <Menu iconShape="square">
                <MenuItem icon={<CgImport />}>
                  Import File
                  <Link to="/fileImport" />
                </MenuItem>
              </Menu>
            </SidebarContent>
          </ProSidebar>
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
